import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import {
    Button,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
import CryptoJS from "crypto-js"
//import jwtDecode from "jwt-decode"

import creditdebit from '../../assets/img/body/bank/creditcard.png'
import mobileBank from '../../assets/img/body/bank/mobilebank.png'
import trueWallet from '../../assets/img/body/bank/truemoney.png'
import wechat from '../../assets/img/body/bank/wechat.png'
import line from '../../assets/img/body/bank/linepay.png'
import kbank from '../../assets/img/body/bank/kbank.png'
import scb from '../../assets/img/body/bank/scb.png'
import krungsri from '../../assets/img/body/bank/bay.png'
import { getOS } from 'getOs';

export default function PackRegis(props) {
    const { id } = useParams()
    const [input, setInput] = useState({})
    const [rub, setRub] = useState()
    const [imgLoad, setImgLoad] = useState(false)
    const kbankValue = []
    const scbValue = []
    const krungsriValue = []
    const [gbInput, setGbInput] = useState({})
    const [IsSubmit, setIsSubmit] = useState(false)
    let user_idRef = useRef()
    const osRef = useRef(getOS()[0].toUpperCase())
    var token = window.localStorage.getItem('auth')
    const { jwtDecode } = require("jwt-decode")

    //console.log(window.localStorage.getItem('auth'))
    //console.log(token)
    //console.log(user_idRef.current)

    if(token!='' && token!='0000000000' && token!=null){

    const decoded = jwtDecode(token)
    //console.log(decoded); 
    //console.log(decoded.exp)
    const jwt_exp = decoded.exp 

    const myCurrentDate = new Date();

    const DateTimeS = Math.floor(new Date().getTime() / 1000)
    //console.log(DateTimeS)
 
    myCurrentDate.setDate(myCurrentDate.getDate()+2);
    const NextDateTimeS = Math.floor(myCurrentDate.getTime() / 1000)
    //console.log(NextDateTimeS);

        if(NextDateTimeS>=jwt_exp){
            alert('กรุณา login ใหม่ก่อนทำการซื้อแพคเกจ')
            window.location.href = '/logout'
        }
    }

    const inputChange = (event) => {
        const { name, value } = event.target
        setInput((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const gbInputChange = (event) => {
        const { name, value } = event.target
        setGbInput((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const paymentNext = (event, product_id, price1, id) => {
        event.preventDefault()
        setIsSubmit(input.payment)
        if (input.payment == 'TrueWallet' || input.payment == 'line' || input.payment == 'Wechat') {
            paymentSubmit(event, product_id, price1, id)
        }
    }
    const paymentSubmit = async (event, product_id, price1, id) => {
        event.preventDefault()
        const refNo = new Date().getTime() + user_idRef.current.slice(0, 2)
        var formData = new FormData()
        formData.append('referenceNo', refNo)
        formData.append('amount', price1)
        formData.append('customerName', input.email)
        formData.append('customerAddress', '')
        formData.append('customerEmail', input.email)
        formData.append('customerTelephone', input.phone)
        formData.append('package_name', product_id)
        formData.append('session', window.localStorage.getItem('auth'))
        formData.append('user_id', user_idRef.current)
        formData.append('package_id', id)
        await axios.post(`${process.env.REACT_APP_API}/v1/receipt`, formData, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(() => {
        })
        if (input.payment == 'MobileBanking') {
            const msha256 = `${CryptoJS.HmacSHA256(`${parseFloat(price1).toFixed(2) + refNo}${process.env.REACT_APP_SERVICE + '/gbpay/result'}${process.env.REACT_APP_API + '/v1/payment'}${input.mbank}`, process.env.REACT_APP_SK)}`
            const params = new URLSearchParams()
            params.append('publicKey', process.env.REACT_APP_PK)
            params.append('amount', parseFloat(price1).toFixed(2))
            params.append('referenceNo', refNo)
            params.append('backgroundUrl', `${process.env.REACT_APP_API + '/v1/payment'}`)
            params.append('responseUrl', `${process.env.REACT_APP_SERVICE + '/gbpay/result'}`)
            params.append('bankCode', input.mbank)
            params.append('checksum', msha256)
            params.append('customerName', input.email)
            params.append('customerEmail', input.email)
            params.append('customerTelephone', input.phone)
            params.append('merchantDefined1', 'test')
            axios.post(`${process.env.REACT_APP_GB}/v2/mobileBanking `, params, {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                }
            }).then(result => {

                //Kbank+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                if (input.mbank == '004') {
                    let a = result.data.split('<input type="hidden"')
                    a.map(b => {
                        kbankValue.push(b.split('"', 6)[5])
                    })
                    window.location.href = `/package/kbank?value1=${kbankValue[1]}&value2=${kbankValue[2]}&value3=${kbankValue[3]}&value4=${kbankValue[4]}&value5=${kbankValue[5]}&value6=${kbankValue[6]}&value7=${kbankValue[7]}&value8=${kbankValue[8]}&value9=${kbankValue[9]}&value10=${kbankValue[10]}&value11=${kbankValue[11]}&value12=${kbankValue[12]}&value13=${kbankValue[13]}&value14=${kbankValue[14]}`
                }
                //Kbank---------------------------------------------------------------------------------------
                //Scb+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                if (input.mbank == '014') {
                    let a = result.data.split('<form action=')
                    a.map(b => {
                        scbValue.push(b.split('"', 2)[1])
                    })
                    window.location = scbValue[1]
                }
                //Scb-----------------------------------------------------------------------------------------
                //Krungsri++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                if (input.mbank == '025') {
                    let a = result.data.split('value=')
                    a.map((b, index) => {
                        if (index != '0') {
                            krungsriValue.push(b.split('"', 2)[1])
                        }
                        window.location = `/package/krungsri?value1=${krungsriValue[0]}&value2=${krungsriValue[1]}&value3=${krungsriValue[4]}`
                    })
                }
                //Krungsri------------------------------------------------------------------------------------
            })
        }
        if (input.payment == 'Wechat') {
            const msha256 = `${CryptoJS.HmacSHA256(`${parseFloat(price1).toFixed(2) + refNo}${process.env.REACT_APP_API + '/v1/payment'}`, process.env.REACT_APP_SK)}`
            const params = new URLSearchParams()
            params.append('publicKey', process.env.REACT_APP_PK)
            params.append('amount', parseFloat(price1).toFixed(2))
            params.append('referenceNo', refNo)
            params.append('responseUrl', `${process.env.REACT_APP_SERVICE + '/gbpay/result'}`)
            params.append('backgroundUrl', `${process.env.REACT_APP_API + '/v1/payment'}`)
            params.append('detail', 'test')
            params.append('customerTelephone', input.phone)
            params.append('checksum', msha256)
            params.append('customerName', input.email)
            params.append('customerEmail', input.email)
            params.append('merchantDefined1', 'test')
            axios.post(`${process.env.REACT_APP_GB}/v2/wechat`, params, {
                responseType: 'blob',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                }
            }).then(result => {
                setRub(URL.createObjectURL(result.data))
            }).finally(() => setImgLoad(true))
        }
        if (input.payment == 'line') {
            const msha256 = `${CryptoJS.HmacSHA256(`${parseFloat(price1).toFixed(2) + refNo}${process.env.REACT_APP_SERVICE + '/gbpay/result'}${process.env.REACT_APP_API + '/v1/payment'}`, process.env.REACT_APP_SK)}`
            document.getElementById('referenceNo').value = refNo
            document.getElementById('checksum').value = msha256
            document.getElementById('lineform').submit()
            // const params = new URLSearchParams()
            // params.append('publicKey', process.env.REACT_APP_PK)
            // params.append('amount', parseFloat(price1).toFixed(2))
            // params.append('referenceNo', refNo)
            // params.append('detail', 'test')
            // params.append('responseUrl', `${process.env.REACT_APP_SERVICE + '/gbpay/result'}`)
            // params.append('backgroundUrl', `${process.env.REACT_APP_API + '/mvhub/payment'}`)
            // params.append('customerTelephone', input.phone)
            // params.append('checksum', msha256)
            // params.append('customerName', input.email)
            // params.append('customerEmail', input.email)
            // params.append('merchantDefined1', 'test')
            // axios.post(`${process.env.REACT_APP_GB}/v2/linepay`, params, {
            //     headers: {
            //         'content-type': 'application/x-www-form-urlencoded',
            //         'Access-Control-Allow-Origin': '*',
            //         'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            //     }
            // }).then(result => console.log('result', result))
            //     .catch(err => console.log('err', err))
        }
        if (input.payment == 'TrueWallet') {
            const msha256 = `${CryptoJS.HmacSHA256(`${parseFloat(price1).toFixed(2) + refNo}${process.env.REACT_APP_SERVICE + '/gbpay/result'}${process.env.REACT_APP_API + '/v1/payment'}`, process.env.REACT_APP_SK)}`
            document.getElementById('referenceNo').value = refNo
            document.getElementById('checksum').value = msha256
            document.getElementById('trueform').submit()
            // const params = new URLSearchParams()
            // params.append('publicKey', process.env.REACT_APP_PK)
            // params.append('amount', parseFloat(price1).toFixed(2))
            // params.append('referenceNo', refNo)
            // params.append('responseUrl', `${process.env.REACT_APP_SERVICE + '/gbpay/result'}`)
            // params.append('backgroundUrl', `${process.env.REACT_APP_API + '/mvhub/payment'}`)
            // params.append('customerTelephone', input.phone)
            // params.append('checksum', msha256)
            // params.append('customerName', input.email)
            // params.append('customerEmail', input.email)
            // params.append('merchantDefined1', 'test')
            // axios.post(`${process.env.REACT_APP_GB}/v2/trueWallet`, params, {
            //     headers: {
            //         'content-type': 'application/x-www-form-urlencoded'
            //     }
            // }).then(result => {
            //     const value = result.data.split('value="')
            //     setTrueWal(value[3].split('"', 1))
            // })
        }
        if (input.payment == 'Credit/Debit') {
            axios.post(`${process.env.REACT_APP_GB}/v2/tokens`, {
                "rememberCard": false,
                "card": {
                    "number": gbInput.cardNumber,
                    "expirationMonth": gbInput.mm,
                    "expirationYear": gbInput.yy,
                    "securityCode": gbInput.cvv,
                    "name": input.email
                }
            }, {
                headers: {
                    Authorization: `Basic ${Buffer.from(process.env.REACT_APP_PK + ':').toString('base64')}`
                }
            }).then(result => {
                if (result.data.resultCode == '00') {
                    axios.post(`${process.env.REACT_APP_GB}/v2/tokens/charge`, {
                        "amount": price1,
                        "referenceNo": refNo,
                        "detail": product_id,
                        "customerName": input.email,
                        "customerEmail": input.email,
                        "customerAddress": "",
                        "customerTelephone": input.phone,
                        "merchantDefined1": "Promotion",
                        "card": {
                            "token": result.data.card.token
                        },
                        "otp": "Y",
                        "responseUrl": `${process.env.REACT_APP_SERVICE + '/gbpay/result'}`,
                        "backgroundUrl": `${process.env.REACT_APP_API + '/v1/payment'}`
                    }, {
                        headers: {
                            Authorization: `Basic ${Buffer.from(process.env.REACT_APP_SK + ':').toString('base64')}`
                        }
                    }).then(result => {
                        window.open(`/package/pay/${result.data.gbpReferenceNo}&${gbInput.cardNumber}&${gbInput.mm}&${gbInput.yy}&${gbInput.cvv}`,'_top')
                    }
                    )
                }
            })
        }
    }
    useEffect(() => {
        if (!localStorage.getItem('auth')) {
            alert('กรุณา login ก่อนสมัครแพ็คเกจ')
            window.location.href = '/logout'
        }
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/userinfo`, 'no', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(result => user_idRef.current = result.data.res_data.user_id)
    }, [])
    const renderPackage = props.datas.data.res_data
        .filter((result) => result.product_id == id)
        .map(data => {
            return (
                <>
                    <Row className='pt-5 pb-5'>
                        <Col sm='12' md='12' lg='4'>
                            <img className='d-block banner-img silhouette' src={data.img_web} alt={data.pack_name} />
                        </Col>
                        <Col sm='12' md='12' lg='8'>
                            <div className='package-card-bg silhouette'>
                                {IsSubmit === false &&
                                    <form onSubmit={(event) => paymentNext(event, data.product_id, data.price1, data.id)} method='post'>
                                        <Row>
                                            <Col sm='12' md='12' lg='6'>
                                                <Row>
                                                    <Col xs='12'>
                                                        <h2 className='ml-4 pt-3 mb-3'>รายละเอียดคำสั่งซื้อ</h2>
                                                        <Row>
                                                            <Col xs='6'>
                                                                <h5 className='ml-4'>รหัส</h5>
                                                            </Col>
                                                            <Col xs='6'>
                                                                <h5 className=''>: {data.product_id}</h5>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='6'>
                                                                <h5 className='ml-4'>อายุ</h5>
                                                            </Col>
                                                            <Col xs='6'>
                                                                <h5 className=''>: {data.pack_day} วัน</h5>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='6'>
                                                                <h5 className='ml-4'>รับชมพร้อมกันสูงสุด</h5>
                                                            </Col>
                                                            <Col xs='6'>
                                                                <h5 className=''>: {data.device_amount} เครื่อง</h5>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm='12' md='12' lg='12'>
                                                        <Row>
                                                            <Col xs='12'>
                                                                <h2 className='ml-4 mt-3 mb-3'>ช่องทางการชำระ</h2>
                                                                <Row>
                                                                    <Col xs='12'>
                                                                        <input type="radio" className='ml-5 mr-1' id="Credit/Debit" name="payment" value="Credit/Debit" onChange={inputChange} />
                                                                        <label className='d-inline' for='Credit/Debit'><img src={creditdebit} style={{ 'width': '30%' }} /> Credit/Debit Card</label>
                                                                    </Col>
                                                                    <Col xs='12'>
                                                                        <input type="radio" className='ml-5 mr-1' id="MobileBanking" name="payment" value="MobileBanking" onChange={inputChange} />
                                                                        <label className='d-inline' for='MobileBanking'><img src={mobileBank} style={{ 'width': '30%' }} /> MobileBanking</label>
                                                                    </Col>
                                                                    <Col xs='12'>
                                                                        <input type="radio" className='ml-5 mr-1' id="TrueWallet" name="payment" value="TrueWallet" onChange={inputChange} />
                                                                        <label className='d-inline' for='TrueWallet'><img src={trueWallet} style={{ 'width': '30%' }} /> TrueWallet</label>
                                                                    </Col>
                                                                    <Col xs='12'>
                                                                        <input type="radio" className='ml-5 mr-1' id="line" name="payment" value="line" onChange={inputChange} />
                                                                        <label className='d-inline' for='line'><img src={line} style={{ 'width': '30%' }} /> Line Pay</label>
                                                                    </Col>
                                                                    <Col xs='12'>
                                                                        <input type="radio" className='ml-5 mr-1' id="Wechat" name="payment" value="Wechat" onChange={inputChange} />
                                                                        <label className='d-inline pr-2' for='Wechat'><img src={wechat} style={{ 'width': '30%', 'backgroundColor': 'white' }} /> Wechat Pay</label>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <div className='warning-input' style={{ width: '100%' }}>
                                                                <Col xs='12'>
                                                                    <Input
                                                                        name='email'
                                                                        className='mb-2 mt-2 packRegis-input'
                                                                        type='email'
                                                                        placeholder='อีเมล'
                                                                        required
                                                                        onChange={inputChange}
                                                                    />
                                                                </Col>
                                                                <Col xs='12'>
                                                                    <Input
                                                                        name='phone'
                                                                        className='mb-2 packRegis-input'
                                                                        type='tel'
                                                                        placeholder='เบอร์โทรศัพท์ (กรอกเบอร์ที่ผูกกับช่องทางการชำระ)'
                                                                        pattern='[0-9]{10}'
                                                                        required
                                                                        onChange={inputChange}
                                                                    />
                                                                </Col>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm='12' md='12' lg='6'>
                                                <div className='warning'>
                                                    <div className='m-2'>
                                                        <p><span>หมายเหตุ：</span>ระบบปฎิบัติการอื่นๆ นอกเหนือจาก Android และ IOS ยังอยู่ในขั้นตอนการพัฒนาเพื่อรองรับการใช้งาน ดังนั้นสำหรับอุปกรณ์ของ Huawei,Oppo และ Xiaomi บางรุ่นยังไม่สามารถรองรับการทำงานที่สมบูรณ์แบบได้ในขณะนี้
                                                        </p>
                                                        <p>***ก่อนตัดสินใจซื้อแพ็คเกจลูกค้าสามารถทดลองการใช้งาน รับชมฟรี!! ทุกเรื่องสองตอน***</p>
                                                        <p>
                                                            *ทางบริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงรวมถึงแก้ไขรายละเอียดแพ็คเกจ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                                                        </p>
                                                        <input type='checkbox' required />
                                                        <span> ยอมรับ (ข้าพเจ้าได้อ่าน และ ยอมรับ <Link to={'/policy/term'} className='footer_btn'>ข้อกำหนดการใช้งานและเงื่อนไข</Link> และ <Link to={'/policy/privacy'} className='footer_btn'>นโยบายความเป็นส่วนตัวของMVHUB)</Link></span>
                                                    </div>
                                                </div>
                                                <div className='warning-btn'>
                                                    <Button className='mt-1 packRegis-btn' type='submit' color='success' block>ถัดไป</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                }
                                {IsSubmit == 'Credit/Debit' &&
                                    <>
                                        <form onSubmit={(event) => paymentSubmit(event, data.product_id, data.price1, data.id)} method='post'>
                                            <Row>
                                                <Col xs='12'>
                                                    <h3 className='text-center mb-2'>{input.payment}</h3>
                                                </Col>
                                                <Col xs='12'>
                                                    <h5 className='text-center mb-2'>กรอกข้อมูลสำหรับการชำระเงิน</h5>
                                                </Col>
                                                <div className='warning-input' style={{ width: '100%' }}>
                                                    <Row>
                                                        <Col xs='10'>
                                                            <label>Card number</label>
                                                        </Col>
                                                        <Col xs='2'>
                                                            <label>CVV</label>
                                                        </Col>
                                                        <Col xs='10'>
                                                            <Input
                                                                name='cardNumber'
                                                                className='mb-2'
                                                                type='tel'
                                                                pattern='[0-9]{16}'
                                                                placeholder='Card Number'
                                                                fullwidth
                                                                required
                                                                onChange={gbInputChange}
                                                            />
                                                        </Col>
                                                        <Col xs='2'>
                                                            <Input
                                                                name='cvv'
                                                                className='mb-2'
                                                                type='tel'
                                                                pattern='[0-9]{3}'
                                                                placeholder='CVV'
                                                                fullwidth
                                                                required
                                                                onChange={gbInputChange}
                                                            />
                                                        </Col>
                                                        <Col xs='6'>
                                                            <label>mm</label>
                                                        </Col>
                                                        <Col xs='6'>
                                                            <label>yy</label>
                                                        </Col>
                                                        <Col xs='6'>
                                                            <Input
                                                                name='mm'
                                                                className='mb-2'
                                                                type='tel'
                                                                placeholder='mm'
                                                                pattern='[0-9]{2}'
                                                                required
                                                                fullwidth
                                                                onChange={gbInputChange}
                                                            />
                                                        </Col>
                                                        <Col xs='6'>
                                                            <Input
                                                                name='yy'
                                                                className='mb-2'
                                                                type='tel'
                                                                placeholder='yy'
                                                                pattern='[0-9]{2}'
                                                                required
                                                                fullwidth
                                                                onChange={gbInputChange}
                                                            />
                                                        </Col>
                                                        <Col xs='12'>
                                                            <Button type='submit' color='success' block>ชำระ</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>
                                        </form>
                                    </>
                                }

                                {IsSubmit == 'MobileBanking' &&
                                    <>
                                        <Col xs='12'>
                                            <Container>
                                                <form onSubmit={(event) => paymentSubmit(event, data.product_id, data.price1, data.id)}>
                                                    <Row>
                                                        {/* <Col sm='12' md='12' lg='4'>
                                                            <input type="radio" id="kbank" name="mbank" value="004" onChange={inputChange} />
                                                            <label className='d-inline' for='kbank'> <img src={kbank} style={{ height: '40%' }} /> <span>กสิกร</span></label>
                                                        </Col> */}
                                                        <Col sm='12' md='12' lg='4'>
                                                            <input type="radio" id="scb" name="mbank" value="014" onChange={inputChange} />
                                                            <label className='d-inline' for='scb'> <img src={scb} style={{ height: '40%' }} /> <span>ไทยพาณิชย์</span></label>
                                                        </Col>
                                                        <Col sm='12' md='12' lg='4'>
                                                            <input type="radio" id="krungsri" name="mbank" value="025" onChange={inputChange} />
                                                            <label className='d-inline pr-2' for='krungsri'> <img src={krungsri} style={{ height: '40%' }} /> <span>กรุงศรี</span></label>
                                                        </Col>
                                                        <Button type='submit' color='success' block>ถัดไป</Button>
                                                        <div>*การชำระผ่าน MobileBanking กรุณาชำระผ่านโทรศัพท์มือถือ*</div>
                                                    </Row>
                                                </form>
                                            </Container>
                                        </Col>
                                    </>
                                }
                                {IsSubmit == 'Wechat' && imgLoad == true &&
                                    <>
                                        <img src={rub} />
                                    </>
                                }
                                {IsSubmit == 'line' &&
                                    <>
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <h3 className='text-center text-yellow'>Line Pay</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className='warning-input' style={{ width: '100%' }}>
                                                    <Col>
                                                        <form name='lineform' id='lineform' onSubmit={(event) => paymentSubmit(event, data.product_id, data.price1, data.id)} action={`${process.env.REACT_APP_GB}/v2/linepay`} method='post'>
                                                            <input type={'hidden'} id='publicKey' name='publicKey' value={process.env.REACT_APP_PK} />
                                                            <input type={'hidden'} id='amount' name='amount' value={parseFloat(data.price1).toFixed(2)} />
                                                            <input type={'hidden'} id='referenceNo' name='referenceNo' value={new Date().getTime() + input.email.slice(0, 2)} />
                                                            <input type={'hidden'} id='detail' name='detail' value={data?.product_id} />
                                                            <input type={'hidden'} id='responseUrl' name='responseUrl' value={`${process.env.REACT_APP_SERVICE + '/gbpay/result'}`} />
                                                            <input type={'hidden'} id='checksum' name='checksum' value={`${CryptoJS.HmacSHA256(`${parseFloat(data.price1).toFixed(2) + new Date().getTime() + input.email.slice(0, 2)}${process.env.REACT_APP_SERVICE + '/gbpay/result'}${process.env.REACT_APP_API + '/v1/payment'}`, process.env.REACT_APP_SK)}`} />
                                                            <input type={'hidden'} id='backgroundUrl' name='backgroundUrl' value={`${process.env.REACT_APP_API + '/v1/payment'}`} />
                                                            <input type={'hidden'} id='customerTelephone' name='customerTelephone' value={input.phone} />
                                                            <input type={'hidden'} id='customerName' name='customerName' value={input.email} />
                                                            <input type={'hidden'} id='customerEmail' name='customerEmail' value={input.email} />
                                                            <input type={'hidden'} id='merchantDefined1' name='merchantDefined1' value={'website'} />
                                                            <Button type='submit' color='success' block>ชำระเงิน</Button>
                                                        </form>
                                                    </Col>
                                                </div>
                                            </Row>
                                        </Container>
                                    </>
                                }
                                {IsSubmit == 'TrueWallet' &&
                                    <>
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <h3 className='text-center text-yellow'>True Wallet</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className='warning-input' style={{ width: '100%' }}>
                                                    <Col>
                                                        {/* <form method="post" action="https://api.gbprimepay.com/v1/trueWallet/payment">
                                                            <Input id="mobile_number" className="Inputborder" type="tel" name="mobile_number" placeHolder="0899999999" pattern="[0-9]{10}" maxLength="10" required="true" value={input.phone.toString()} disabled />
                                                            <input type="text" name="ptx_id" value={trueWal[0]} />
                                                            <Button color='success' type='submit' block>ชำระเงิน</Button>
                                                        </form> */}

                                                        <form name='trueform' id='trueform' onSubmit={(event) => paymentSubmit(event, data.product_id, data.price1, data.id)} action={`${process.env.REACT_APP_GB}/v2/trueWallet`} method='post'>
                                                            <input type={'hidden'} id='publicKey' name='publicKey' value={process.env.REACT_APP_PK} />
                                                            <input type={'hidden'} id='amount' name='amount' value={parseFloat(data.price1).toFixed(2)} />
                                                            <input type={'hidden'} id='referenceNo' name='referenceNo' value={new Date().getTime() + input.email.slice(0, 2)} />
                                                            <input type={'hidden'} id='detail' name='detail' value={data?.product_id} />
                                                            <input type={'hidden'} id='responseUrl' name='responseUrl' value={`${process.env.REACT_APP_SERVICE + '/gbpay/result'}`} />
                                                            <input type={'hidden'} id='checksum' name='checksum' value={`${CryptoJS.HmacSHA256(`${parseFloat(data.price1).toFixed(2) + new Date().getTime() + input.email.slice(0, 2)}${process.env.REACT_APP_SERVICE + '/gbpay/result'}${process.env.REACT_APP_API + '/v1/payment'}`, process.env.REACT_APP_SK)}`} />
                                                            <input type={'hidden'} id='backgroundUrl' name='backgroundUrl' value={`${process.env.REACT_APP_API + '/v1/payment'}`} />
                                                            <input type={'hidden'} id='customerTelephone' name='customerTelephone' value={input.phone} />
                                                            <input type={'hidden'} id='customerName' name='customerName' value={input.email} />
                                                            <input type={'hidden'} id='customerEmail' name='customerEmail' value={input.email} />
                                                            <input type={'hidden'} id='merchantDefined1' name='merchantDefined1' value={'website'} />
                                                            <Button type='submit' color='success' block>ชำระเงิน</Button>
                                                        </form>
                                                    </Col>
                                                </div>
                                            </Row>
                                        </Container>
                                    </>
                                }
                            </div>
                        </Col >
                    </Row >
                </>
            )
        })
    return (
        <>
            <div className='bg-img'>
                <Container className='pt-5 package-container'>
                    <Row>
                        {renderPackage}
                    </Row>
                </Container>
            </div>
        </>
    )
}